<template>
  <div class="pageCenter">
    <el-backtop></el-backtop>
    <div class="banner">
      <img src="../assets/image/首页/顶部banner.png" alt="" />
    </div>
    <div class="text-box">
      <span id="span1"></span>
      <span id="span2">专注公共交通领域，提供智慧出行综合服务</span>
      <span id="span3"></span>
    </div>
    <!-- 产品卡片 -->
    <div class="product">
      <ul class="product_card">
        <li
          v-for="(item, index) in dataLi"
          :key="index"
          @mouseenter="changeActive(index)"
          @mouseleave="removeActive(index)"
          @click="toProductPage(index)"
          class="produt_card_box"
          v-bind:style="{
            backgroundImage: 'url(' + item.bg + ')',
            backgroundRepeat: 'no-repeat'
          }"
        >
          <div :class="indexIten === index ? 'enter-bg' : ''">
            <div
              :class="
                indexIten === index
                  ? 'product_card_top_enter'
                  : 'product_card_top_remove'
              "
            >
              <img :src="item.icon" alt="" />
              <p class="produt_card_title">{{ item.title }}</p>
            </div>
            <div class="product_card_bottom">
              <div
                v-show="indexIten === index"
                style="margin: 0 30px; font-size: 18px; line-height: 35px"
              >
                {{ item.info }}
              </div>
              <img :src="indexIten === index ? iconSpread : iconPush" alt="" />
            </div>
          </div>
        </li>
      </ul>
    </div>
    <!-- 公司简介 -->
    <div class="company">
      <div class="company_top">
        <h3>武汉轩迪科技有限公司</h3>
        <p>
          武汉轩迪是一家创新型的科技公司，本公司依托物联网、大数据、人工智能等数字化技术，遵循”智慧交通“”便捷公交“”绿色公交“的行业发展理念，对接公共交通各类资源，打通公共交通营运、安全能耗、线上线下融合、支付等相关数据节点，构建公共交通大数据系统融合决策平台，为公共交通行业提供数字化决策服务，助力公共交通行业达到降本增效、智慧运营的目的
        </p>
      </div>
      <div class="company_bottom">
        <h3>2021再次起航</h3>
        <p>
          公司座落于武汉汉街国际总部<br />
          经过多年沉淀，公司在全国发展了超100家公交企业用户<br />
          超一流的研发团队，助力公司在大数据领域走在行业前端，并获得用户高度认可<br />
          专业、专注、高效一直是企业发展的源动力
        </p>
      </div>
    </div>
    <!-- 行业用户 -->
    <div class="userNum">
      <div class="userNum_border">
        <el-row>
          <el-col :span="8">
            <div class="userNum_item">
              <h1>100+</h1>
              <b>行业用户</b>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="userNum_item">
              <h1>3000W+</h1>
              <b>APP用户</b>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="userNum_item">
              <h1>20+</h1>
              <b>合作单位</b>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- 我们的优势 -->
    <div class="ourAdvantage">
      <div class="ourAdvantage-item secondWidth">
        <h1>我们的优势</h1>
        <div class="ourAdvantage-item-01">
          <el-row>
            <el-col :span="2">
              <div class="ourAdvantage_item_num">01</div>
            </el-col>
            <el-col :span="13">
              <h4 class="ourAdvantage_item_title">积累</h4>
              <b class="ourAdvantage_item_content">
                企业团队在公交领域沉淀了20余年，有着丰富的行业经验与客户资源,竭力为公共行业的数字化转型做最专业的服务
              </b>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="2">
              <div class="ourAdvantage_item_num">02</div>
            </el-col>
            <el-col :span="13">
              <h4 class="ourAdvantage_item_title">团队</h4>
              <b class="ourAdvantage_item_content">
                经过多年沉淀，我司组建起一支成熟、专业、高效的研发团队；
              </b>
              <b class="ourAdvantage_item_content">
                团队全员在公共交通大数据领域、APP领域都有专业业务知识与研发能力
              </b>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="2">
              <div class="ourAdvantage_item_num">03</div>
            </el-col>
            <el-col :span="13">
              <h4 class="ourAdvantage_item_title">产品</h4>
              <b class="ourAdvantage_item_content">
                为了传统公共交通的数字化升级与转型，结合行业工作痛点，我司设计了大数据决策云平台，智慧安全管理平台，智慧出行平台，数据融合平台、实施监控平台等，形成公交行业大脑
              </b>
            </el-col>
          </el-row>
        </div>
        <img src="../assets/image/首页/电脑.png" alt="" />
      </div>
    </div>
    <!--  -->
    <div class="centerPicture"></div>
    <!-- 联系我们 -->
    <div>
      <div class="callUs secondWidth">
        <h1>联系我们</h1>
        <div class="contact-us">
          <input
            v-model="name"
            type="text"
            placeholder="输入姓名"
            class="callUs_input callUs_name_img"
          />
          <input
            v-model="email"
            type="text"
            id="emailInput"
            placeholder="输入邮件"
            class="callUs_input callUs_email_img"
          />
        </div>
        <div class="callUs_box callUs_input callUs_info_img">
          <input
            v-model="info"
            type="text"
            placeholder="输入信息"
            class="callUs_info_input"
          />
        </div>
        <button @click="onSubmit">发送信息</button>
      </div>
    </div>
  </div>
</template>
<script>
import { ElMessage } from 'element-plus'
export default {
  data() {
    return {
      name: '',
      email: '',
      info: '',
      indexIten: 0,
      iconPush: require('@/assets/image/首页/下拉_icon.png'),
      iconSpread: require('@/assets/image/首页/展开_icon.png'),
      dataLi: [
        {
          bg: require('@/assets/image/首页/出行服务_bg.png'),
          icon: require('@/assets/image/首页/出行服务_icon.png'),
          title: '出行服务',
          info: ' 一款针对公交乘车场景，包含乘车、网上营业厅、实时公交、定制公交等综合服务类APP'
        },
        {
          bg: require('@/assets/image/首页/云平台_bg.png'),
          icon: require('@/assets/image/首页/云平台_icon.png'),
          title: '大数据融合平台',
          info: ' 一款融合公交营运、支付、等各类数据，为节能减耗、线网优化服务的综合类决策云平台'
        },
        {
          bg: require('@/assets/image/首页/安全_bg.png'),
          icon: require('@/assets/image/首页/安全_icon.png'),
          title: '安全智慧管理平台',
          info: ' 一款囊括车辆、人员、监控、安全学习等各类过程管理的综合性服务平台'
        },
        {
          bg: require('@/assets/image/首页/实时大屏_bg.png'),
          icon: require('@/assets/image/首页/实时大屏_icon.png'),
          title: '公共交通智能监控平台',
          info: ' 一款服务于交通运管中心等决策机构，整合了公共交通的运营、支付等各类实时数据的监测分析平台'
        }
      ],
      form: {}
    }
  },
  methods: {
    toProductPage(index) {
      console.log(index)
      if (index === 0) {
        this.$router.push('/travelApp')
        window.scrollTo(0, 0)
      } else if (index === 1) {
        this.$router.push('/productService')
        window.scrollTo(0, 0)
      } else if (index === 2) {
        this.$router.push('/securityPlatform')
        window.scrollTo(0, 0)
      } else if (index === 3) {
        this.$router.push('/monitoringPlatform')
        window.scrollTo(0, 0)
      }
    },
    changeActive(index) {
      this.indexIten = index
    },
    removeActive(index) {
      this.indexIten = -1
    },
    onSubmit() {
      if (this.name && this.email && this.info) {
        this.$message({
          message: '发送成功！',
          type: 'success'
        })
        this.name = ''
        this.email = ''
        this.info = ''
      } else {
        ElMessage('请填写完整信息')
      }
    }
  }
}
</script>
<style lang="less" scoped>
.banner img {
  width: 100%;
}
.text-box {
  margin: 74px 0 106px;
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  #span2 {
    margin: 0 33px;
    vertical-align: middle;
    font-family: 'HYRuiHuSongW';
    font-size: 24px;
    font-weight: 900;
  }
  #span1,
  #span3 {
    display: inline-block;
    width: 40px;
    border-top: 2px solid #333333;
  }
}
.product {
  display: flex;
  height: 600px;
  text-align: center;
  margin: 0 164px;
}
.product_card {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 500px;
  text-align: center;
  flex-flow: row wrap;
  li {
    flex: 0.25; /*一行排列4个*/
  }
}
.produt_card_box {
  height: 500px;
  margin: 35px;
  position: relative;
}
.product_card_top_enter {
  pointer-events: none;
  width: 160px;
  height: 170px;
  position: absolute;
  left: 50%;
  top: 32%;
  transform: translate(-50%, -50%);
  .produt_card_title {
    font-size: 30px;
    color: #fff;
  }
}
.product_card_top_remove {
  pointer-events: none;
  width: 160px;
  height: 170px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  .produt_card_title {
    font-size: 35px;
    color: #fff;
  }
}
.product_card_bottom {
  pointer-events: none;
  width: 100%;
  height: 170px;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 28px;
  img {
    position: absolute;
    left: 50%;
    bottom: -60px;
    transform: translate(-50%, -50%);
  }
}
.enter-bg {
  background-color: rgba(0, 70, 157, 0.7);
  width: 100%;
  height: 100%;
}
.secondWidth {
  margin-left: 164px;
  margin-right: 164px;
}
.company {
  position: relative;
  width: 100%;
  height: 600px;
  background: url(../assets/image/首页/公司介绍_bg.png) no-repeat;
  p {
    font-size: 18px;
  }
  .company_top {
    width: 38.5%;
    height: 252px;
    position: absolute;
    left: 230px;
    top: 80px;
    padding-left: 40px;
    color: #fff;
    p {
      margin-right: 40px;
      line-height: 30px;
    }
    h3 {
      font-size: 37px;
      margin-bottom: 20px;
    }
  }
  .company_bottom {
    width: 38.5%;
    height: 212px;
    position: absolute;
    left: 231px;
    top: 332px;
    padding-top: 30px;
    padding-left: 40px;
    color: #fff;
    background: #d1b235;
    h3 {
      font-size: 37px;
      margin-bottom: 20px;
    }
  }
}
.userNum {
  width: 100%;
  height: 250px;
  .userNum_border {
    width: 82%;
    margin: 0 8%;
    height: 250px;
    border-bottom: 1px solid #e6e6e6;
  }
  .userNum_item {
    margin-top: 60px;
    text-align: center;
    h1 {
      color: #3469d3;
      font-size: 72px;
      font: 'MicrosoftYaHei-Bold';
    }
    b {
      font-size: 24px;
      color: #666;
    }
  }
}
.ourAdvantage {
  width: 100%;
  height: 920px;
  position: relative;
  .ourAdvantage-item {
    img {
      position: absolute;
      right: 0;
      top: 110px;
    }
    margin-top: 170px;
    .el-row {
      margin-bottom: 95px;
    }
    h1 {
      font-size: 70px;
      color: #3468d2;
      margin-bottom: 65px;
    }
    .ourAdvantage_item_num {
      font-size: 100px;
      color: #999999;
    }
    .ourAdvantage_item_title {
      margin-bottom: 10px;
      font-size: 30px;
      color: #333333;
    }
    .ourAdvantage_item_content {
      display: inline-block;
      font-size: 18px;
      color: #999999;
      width: 740px;
      line-height: 25px;
    }
  }
}
.centerPicture {
  width: 100%;
  height: 200px;
  background: url('../assets/image/首页/中间区域.png');
}
.callUs {
  /* display: flex; */
  flex-direction: column;
  height: 1033px;
  /* margin: 95px 0; */
  h1 {
    font-size: 70px;
    color: #3468d2;
    margin: 93px 0 116px;
    text-align: right;
  }
  .contact-us {
    display: flex;
    input {
      flex: 0.5;
    }
  }
}
.callUs_input {
  width: 100%;
  height: 110px;
  background-position: 40px 50%;
  background-repeat: no-repeat;
  padding: 8px 10px 8px 100px;
  border: 1px solid #ddd;
  text-align: left;
  font-size: 22px;
  outline: none;
  margin-bottom: 30px;
}
#emailInput {
  margin-left: 30px;
}
.callUs_name_img {
  background-image: url('../assets/image/首页/姓名.png');
  background-size: 34px 40px;
}
.callUs_email_img {
  background-image: url('../assets/image/首页/邮箱.png');
  background-size: 44px 30px;
}
.callUs_info_img {
  height: 300px;
  background-position: 38px 7%;
  background-image: url('../assets/image/首页/信息.png');
}
.callUs_info_input {
  background: none;
  border: none !important;
  outline: none;
  padding-left: 0 !important;
  font-size: 22px !important;
  height: 50px !important;
}
.callUs_input_message {
  width: 100%;
  height: 380px;
}
button {
  width: 100%;
  height: 100px;
  background: #3468d2;
  color: #fff;
  font-size: 30px;
  border: none;
}
</style>
